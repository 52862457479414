import React from "react"
import Moment from "moment"
import { HiOutlineCursorClick } from "react-icons/hi"

const NewsTicker = props => {
  return (
    <div>
      <div className="flex flex-col bg-gray-300 dark:bg-zinc-900 m-auto p-auto py-8">
        <h1 className="sm:text-3xl mx-auto text-2xl font-bold text-center text-gray-800 museo dark:text-zinc-200 mb-0">
          BUDMEN BULLETIN
        </h1>
        <p className="text-xs font-light tracking-widest text-center mx-auto text-zinc-700 dark:text-zinc-400 mb-4">
          PRESS COVERAGE FROM AROUND THE WORLD WIDE WEB
        </p>
        <div className="flex overflow-x-scroll hide-scroll-bar h-full overflow-y-hidden	">
          <div className="flex flex-nowrap gap-5 ml-4 ">
            {props.press.map((pressCoverage, index) => (
              <a
                href={pressCoverage.url}
                className="text-gray-800 dark:text-zinc-300 border border-gray-400 dark:border-zinc-800 h-42 w-80 hover:opacity-70"
                rel="noreferrer"
                target="_blank"
                key={index}
              >
                <div className="w-full h-full bg-gray-300 border-gray-400 dark:bg-zinc-800 mb-4">
                  <div className="p-2">
                    <div className="flex relative border-b border-gray-400 p-1 dark:border-zinc-500 justify-between items-center mb-2">
                      <span className="rounded-full text-gray-300 dark:text-zinc-800 text-[0.5rem] px-1 bg-gray-400">
                        Press
                      </span>
                      <span className="text-[0.6rem] text-gray-800 italic dark:text-zinc-400">
                        {pressCoverage.source}
                      </span>
                      <span className="text-[0.6rem] text-gray-800 dark:text-zinc-400">
                        {Moment(pressCoverage.date).format("MMMM D, YYYY")}
                      </span>
                    </div>
                    <h3
                      className={
                        (index === 1 && "text-lg hwt tracking-wide") ||
                        (index % 2 === 0 && "font-bold text-lg museo") ||
                        (index % 3 === 0 && "font-bold text-lg industryBase")
                      }
                    >
                      {pressCoverage.title}
                    </h3>
                    <p className="text-xs mt-2">{pressCoverage.excerpt}</p>
                  </div>
                </div>
              </a>
            ))}
            <a href="/press" className=" h-42 w-80 hover:opacity-70">
              <div className="w-full h-full mb-4">
                <div className="p-2 text-center flex flex-col justify-center items-center mt-12">
                  <h3 className="font-bold text-lg">Want to read more?</h3>
                  <p className="text-xs mt-2">Click to visit our press page</p>
                  <HiOutlineCursorClick className="text-3xl" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n.hide-scroll-bar {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.hide-scroll-bar::-webkit-scrollbar {\n  display: none;\n}\n",
        }}
      />
    </div>
  )
}
export default NewsTicker
